<template>
  <HomeLayout>
    <b-container
      class="d-flex flex-column align-items-start justify-content-start px-0"
    >
      <!-- card container row -->
      <b-row
        class="
          d-flex
          flex-row
          align-items-stretch
          justify-content-center
          w-100
          card-row
        "
        no-gutters
      >
        <b-card
          no-body
          class="
            overflow-hidden
            border border-light
            shadow-sm
            w-100
            card-container
          "
        >
          <b-row class="px-3 py-0 body-container" no-gutters>
            <!-- left column -->
            <b-col class="px-0 px-md-2 py-3 left-column">
              <b-row
                v-if="startedList.length == 0"
                class="
                  rounded=0
                  d-flex
                  flex-row
                  align-items-center
                  justify-content-center
                  w-100
                  mt-lg-5
                  py-lg-5
                "
                no-gutters
              >
                <h6
                  class="
                    text-left text-dark text-large
                    font-weight-semi-bold font-primary
                  "
                >
                  no data to show
                </h6>
              </b-row>
              <b-row
                class="
                  rounded=0
                  d-flex
                  flex-row
                  align-items-center
                  justify-content-center
                  w-100
                  mt-lg-5
                  py-lg-5
                "
                no-gutters
              >
                <b-col cols="12" lg="9">
                  <div class="accordion border-transaparent" role="tablist">
                    <b-card class="test"
                      v-for="(value, key) in startedList"
                      :key="key"
                      no-body
                    >
                      <!-- <b-card-header header-tag="header" class="p-1" role="tab"> -->
                      <b-button
                        block
                        v-b-toggle="'accordion-' + key"
                        variant="transparent"
                        squared
                        class="accordionBtn text-weight-bold font-primary"
                        > <span class="ml-4">{{ value.title }}</span>

                        <font-awesome-icon
                          class="
                            text-dark
                            align-items-end
                            collapse-icon
                            justify-content-end
                          "
                          icon="angle-down"
                          size="lg"
                          aria-label="Help"
                        ></font-awesome-icon>
                      </b-button>
                      <!-- </b-card-header> -->
                      <b-collapse
                        :id="'accordion-' + key.toString()"
                        accordion="my-accordion"
                        role="tabpanel"
                      >
                        <b-card-body>
                          <b-card-text v-html="value.description" class="ml-3"></b-card-text>
                        </b-card-body>
                      </b-collapse>
                    </b-card>
                  </div>
                </b-col>
              </b-row>
            </b-col>
            <b-overlay :show="isAPILoading" no-wrap></b-overlay>
          </b-row>
        </b-card>
      </b-row>
    </b-container>
  </HomeLayout>
</template>

<script>
// components
// @ is an alias to /src
import HomeLayout from "@/layout/HomeLayout";

// services
import { GetAllInfos } from "@/services/content.service";

export default {
  name: "Earnings",
  components: {
    HomeLayout,
  },
  data() {
    return {
      startedList: [
        // {
        //   title: "Sunt in culpa qui officia deserunt?",
        //   text: "weckwlkemcwekcmwkeclkwmclkmwelkfcmlkwemclkwemclkewmclkewmclkew",
        // },
        // {
        //   title: "Lorem ipsum dolor sit amet?",
        //   text: "weckwlkemcwekcmwkeclkwmclkmwelkfcmlkwemclkwemclkewmclkewmclkew",
        // },
        // {
        //   title: "Tempor incididunt ut aliqua?",
        //   text: "weckwlkemcwekcmwkeclkwmclkmwelkfcmlkwemclkwemclkewmclkewmclkew",
        // },
        // {
        //   title: "Quis nostrud consequat?",
        //   text: "weckwlkemcwekcmwkeclkwmclkmwelkfcmlkwemclkwemclkewmclkewmclkew",
        // },
        // {
        //   title: "Ut enim ad minim veniami?",
        //   text: "weckwlkemcwekcmwkeclkwmclkmwelkfcmlkwemclkwemclkewmclkewmclkew",
        // },
      ],
      isAPILoading: false,
    };
  },

  mounted() {
    this.initFn();
  },
  watch: {},
  methods: {
    async initFn() {
      try {
        this.isAPILoading = true;
        let { data } = await GetAllInfos();
        if (data.code == 200 || data.message == "success") {
          this.startedList = data.content;
        } else {
          let payloadNotify = {
            isToast: true,
            title: "ERROR! Infos",
            content: "Unable to retrieve Info Details' data! Please try again.",
            variant: "danger",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
        }
        this.isAPILoading = false;
      } catch (error) {
        console.log("ERROR GS01");
      } finally {
        this.isAPILoading = false;
      }
    },
  },
};
</script>

<style scoped>
/* table card */
.card-row {
  height: 100%;
}

.card-row .card-container {
  min-height: 560px;
  border-radius: 16px 16px 16px 16px;
}

.body-container {
  /* display: grid;
  grid-template-columns: 48% 4% 48%;
  height: 100%; */
}
.accordionBtn {
  box-sizing: border-box;
  height: 50px;
  background: #f6f8fa;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: left;
    box-shadow: none;
border: 1px solid rgba(135, 147, 163, 0.25);
}
.collapse-icon {
  position: absolute;
  right: 3%;
}
.body-container .left-column .inner-row-grid {
  display: grid;
  grid-template-columns: 25% 70%;
  grid-gap: 5%;
}

.body-container .left-column .inner-row-grid .admin-type-badge {
  width: max-content;
}

.body-container .column-divider {
  width: 0;
  height: 100%;
  border-right: 1px solid var(--light);
}

.footer-img {
  max-width: 150px;
}

.version-number {
  font-size: 0.7rem;
  line-height: 20px;
}
.contact-number-input.form-group >>> input.form-control {
  border-left: 0px solid transparent;
  border-radius: 0px 4px 4px 0px;
}
.contact-number-input >>> .input-group-prepend {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #dee2e6;
  border-color: var(--stroke-light);
  box-shadow: 0px 2px 8px var(--shadow-soft);
  min-width: 60px;
  margin-left: 0;
}
.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}
.test{
  border: none;
}
/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .body-container {
    grid-template-columns: 100%;
    grid-gap: 0.3rem;
  }

  .body-container .column-divider {
    width: 100%;
    height: 0;
    border-right: none;
    border-top: 1px solid var(--light);
  }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .body-container .left-column .inner-row-grid {
    grid-template-columns: 100%;
    grid-gap: 0.3rem;
  }
  .collapse-icon {
  position: absolute;
  right: 5%;
}
}
</style>
